.hippocratic-ai-page {
  background-color: transparent;
  min-height: 100vh;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.case-study-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2.5rem;
  font-family: "Inter", sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Content Preview Container (TOC + Video) */
.content-preview-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 2rem 0 3rem;
  width: 100%;
}

/* Table of Contents */
.toc-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem 1.75rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  border: 1px solid #e0e0e0;
}

.toc-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #2a5885;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.toc-list {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.toc-list li {
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  border-left: 2px solid transparent;
  transition: all 0.2s ease;
}

.toc-list li.active {
  border-left: 2px solid #2a5885;
}

.toc-list a {
  text-decoration: underline;
  color: #2a5885;
  font-size: 1.05rem;
  display: block;
  padding: 0.25rem 0;
  transition: all 0.2s ease;
  font-weight: 500;
}

.toc-list li.active a {
  color: #1e4b6d;
  font-weight: 600;
}

.toc-list a:hover {
  color: #1e4b6d;
  text-decoration: underline;
}

.toc-highlights {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #e0e0e0;
}

.toc-highlights h4 {
  font-size: 1.1rem;
  color: #2a5885;
  margin-bottom: 0.75rem;
}

.toc-highlights ul {
  list-style: none;
  padding: 0;
}

.toc-highlights li {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: #666;
  padding-left: 1rem;
  position: relative;
}

.toc-highlights li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #2a5885;
}

/* Video Preview */
.video-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.video-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #2a5885;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.video-caption {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.95rem;
  color: #666;
  font-style: italic;
}

/* Author Info */
.article-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eaeaea;
}

.author-info {
  display: flex;
  align-items: center;
}

.author-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  border: 2px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.author-details {
  display: flex;
  flex-direction: column;
}

.author-name {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 0.25rem;
  color: #333;
}

.author-title {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.article-date {
  text-align: right;
  font-size: 0.9rem;
  color: #777;
}

.article-date p {
  margin: 0.25rem 0;
}

.article-summary {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  border-left: 4px solid #2a5885;
}

.summary-text {
  margin: 0;
  font-size: 1.05rem;
  line-height: 1.6;
  color: #444;
}

.back-button-container {
  margin-bottom: 2rem;
}

.back-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #f8f9fa;
  color: #2a5885;
  font-weight: 500;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
}

.back-button:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.back-to-top-container {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.back-to-top {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #2a5885;
  color: white;
  font-weight: 500;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.back-to-top:hover {
  background-color: #1e4b6d;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.intro-section {
  margin-bottom: 3rem;
}

.intro-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  line-height: 1.2;
}

.case-study-navigation {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.nav-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.nav-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.nav-item h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #2a5885;
}

.nav-item p {
  color: #666;
  font-size: 0.95rem;
}

.case-study-content {
  margin-top: 2rem;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .content-preview-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .article-meta {
    flex-direction: column;
    align-items: flex-start;
  }

  .author-info {
    margin-bottom: 1rem;
  }

  .article-date {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .case-study-container {
    padding: 1rem;
  }

  .intro-section h1 {
    font-size: 2rem;
  }

  .nav-item {
    padding: 1rem;
  }
}

/* Section-specific styling will be added as sections are developed */
