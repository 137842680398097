.polaris-architecture-wrapper {
  width: 100%;
  max-width: 1200px;
  position: relative;
  margin: 0 auto 15px;
  padding: 0 0 15px;
  box-sizing: border-box;
  overflow: visible;
}

.polaris-architecture-diagram {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f0f5ff;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  margin: 15px auto;
  border: 1px solid #e0e7f2;
  min-height: 650px;
  max-height: 700px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  align-items: stretch;
  gap: 0;
}

/* Common section styles */
.diagram-section {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0;
  margin: 0;
  background-color: white;
  box-shadow: none;
  transition: all 0.3s ease;
  overflow: visible;
  height: auto;
  border: none;
}

.section-title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #2a5885;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eaeaea;
}

/* Left section - Patient Flow */
.patient-flow-section {
  flex: 0.5;
  border-left: 4px solid #348792;
  border-right: 1px solid #e0e7f2;
  background-color: white;
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Container for the main flow */
.patient-flow-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  padding: 15px 10px;
  width: 100%;
  max-width: 160px;
  margin: 0 auto;
}

/* Steps Container */
.flow-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  position: relative;
  z-index: 2;
}

/* Individual step */
.flow-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
  width: 100%;
}

.flow-step:nth-child(2),
.flow-step:nth-child(4) {
  margin-bottom: 30px;
}

/* Last step doesn't need bottom margin */
.flow-step:last-child {
  margin-bottom: 0;
}

/* Styling for rounded nodes (Patient Speaks, AI Speaks) */
.flow-node {
  padding: 6px 15px;
  background-color: white;
  border: 1px solid #2a5885;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #2a5885;
  text-align: center;
  width: 90%;
  max-width: 140px;
  box-shadow: 0 1px 3px rgba(42, 88, 133, 0.15);
}

/* Styling for rectangular boxes */
.flow-box {
  padding: 6px 10px;
  background-color: white;
  border: 1px solid #c8d5e9;
  border-radius: 5px;
  font-size: 12px;
  color: #444;
  text-align: center;
  width: 90%;
  max-width: 140px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* Styling for the highlighted Polaris box */
.flow-box-highlight {
  padding: 7px 10px;
  background-color: #348792;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: 90%;
  max-width: 140px;
  box-shadow: 0 2px 5px rgba(52, 135, 146, 0.35);
  letter-spacing: 0.3px;
}

/* Downward arrows between flow steps */
.flow-arrow-down {
  height: 18px;
  width: 1px;
  background-color: #348792;
  margin: 8px 0;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.flow-arrow-down::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #348792;
}

/* Remove all extra lines and arrows */
.patient-flow-section::before,
.patient-flow-section::after,
.flow-step:first-child .flow-node::before,
.flow-steps::before,
.flow-steps::after {
  display: none;
}

/* Middle section - Primary Agent */
.primary-agent-section {
  flex: 0.8;
  background-color: white;
  border-left: none;
  border-right: 1px solid #e0e7f2;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.primary-agent-section.highlighted {
  box-shadow: 0 3px 12px rgba(42, 88, 133, 0.3);
  background-color: rgba(235, 243, 255, 0.5);
}

.primary-agent-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 6px 8px;
  background-color: rgba(42, 88, 133, 0.04);
  border: 2px solid #2a5885;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(42, 88, 133, 0.12);
  width: 85%;
  max-width: 200px;
  margin: 10px auto;
}

.agent-subtitle {
  font-size: 12px;
  color: #1d4268;
  margin: 0 0 6px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(42, 88, 133, 0.3);
  width: 100%;
}

.capabilities-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.capability-pill {
  padding: 4px 6px;
  background-color: rgba(42, 88, 133, 0.2);
  border-radius: 12px;
  font-size: 11px;
  color: #1d4268;
  text-align: center;
  border: 1px solid rgba(42, 88, 133, 0.5);
  white-space: normal;
  word-break: break-word;
  margin: 0;
  font-weight: 500;
}

/* Right section - Support Agents */
.support-agents-section {
  flex: 2;
  background-color: white;
  border-right: 4px solid #5a6a9c;
  padding: 10px;
}

.support-agents-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: visible;
}

.support-agents-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  overflow: visible;
  align-content: start;
}

.support-agent {
  padding: 10px;
  background-color: rgba(107, 125, 179, 0.04);
  border: 1px solid #6b7db3;
  border-radius: 6px;
  transition: all 0.3s ease;
  box-shadow: 0 1px 4px rgba(107, 125, 179, 0.1);
  position: relative;
  z-index: 2;
  min-height: 100px;
}

.support-agent.highlighted {
  transform: translateY(-2px);
  box-shadow: 0 3px 8px rgba(107, 125, 179, 0.25);
  background-color: rgba(238, 240, 255, 0.5);
  border-color: #6b7db3;
  border-width: 1.5px;
}

.support-agent-title {
  margin: 0 0 5px;
  font-size: 12px;
  color: #5a6a9c;
  text-align: center;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(107, 125, 179, 0.3);
  font-weight: 500;
}

.support-capabilities {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.support-capability-pill {
  padding: 4px 8px;
  background-color: rgba(107, 125, 179, 0.2);
  border-radius: 12px;
  font-size: 10px;
  color: #4c5a87;
  text-align: center;
  border: 1px solid rgba(107, 125, 179, 0.5);
  white-space: normal;
  word-break: break-word;
}

/* Connection lines */
.connection-lines {
  display: none;
}

/* Positions for connection lines */
.connection-checklist {
  width: 10%;
  top: 25%;
  left: 33%;
  transform: rotate(15deg);
}

.connection-medication {
  width: 10%;
  top: 30%;
  left: 33%;
  transform: rotate(10deg);
}

.connection-labs {
  width: 10%;
  top: 35%;
  left: 33%;
  transform: rotate(5deg);
}

.connection-hospital {
  width: 10%;
  top: 40%;
  left: 33%;
  transform: rotate(0deg);
}

.connection-privacy {
  width: 10%;
  top: 45%;
  left: 33%;
  transform: rotate(-5deg);
}

.connection-ehr {
  width: 10%;
  top: 50%;
  left: 33%;
  transform: rotate(-10deg);
}

.connection-nutrition {
  width: 10%;
  top: 55%;
  left: 33%;
  transform: rotate(-15deg);
}

.connection-intervention {
  width: 10%;
  top: 60%;
  left: 33%;
  transform: rotate(-20deg);
}

/* Responsive adjustments */
@media (max-width: 1300px) {
  .polaris-architecture-wrapper {
    padding: 0 15px;
  }
}

@media (max-width: 1200px) {
  .polaris-architecture-diagram {
    flex-direction: column;
    min-height: auto;
  }

  .diagram-section {
    margin: 8px;
    height: auto;
  }

  .patient-flow-section,
  .primary-agent-section,
  .support-agents-section {
    flex: 1;
  }

  .connection-lines {
    display: none;
  }

  .model-info-legend {
    position: relative;
    bottom: auto;
    right: auto;
    margin: 15px auto 0;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .polaris-architecture-wrapper {
    padding: 0 5px;
  }

  .support-agents-wrapper {
    grid-template-columns: 1fr;
  }

  .capability-pill,
  .support-capability-pill {
    font-size: 10px;
  }
}

/* Animation for hover effects */
@keyframes pulse {
  0% {
    box-shadow: 0 2px 6px rgba(42, 88, 133, 0.15);
  }
  50% {
    box-shadow: 0 3px 9px rgba(42, 88, 133, 0.25);
  }
  100% {
    box-shadow: 0 2px 6px rgba(42, 88, 133, 0.15);
  }
}

/* Caption and Legend Container */
.caption-legend-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0;
  width: 100%;
  gap: 20px;
}

/* Diagram Caption */
.diagram-caption {
  flex: 1;
  padding: 0 15px 0 0;
  max-width: 65%;
}

.caption-title {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin: 0 0 8px 0;
}

.caption-description {
  font-size: 13px;
  color: #555;
  line-height: 1.5;
  margin: 0;
}

/* Model information legend/key - updated for flex layout */
.model-info-legend {
  position: relative;
  margin: 0;
  background-color: white;
  border: 1px solid #e0e7f2;
  border-radius: 6px;
  padding: 12px 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  max-width: 300px;
  z-index: 10;
  font-size: 11px;
  color: #444;
  line-height: 1.5;
  float: none;
  flex-shrink: 0;
}

.legend-title {
  font-size: 12px;
  font-weight: 600;
  color: #444;
  margin-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid #e0e7f2;
  padding-bottom: 6px;
}

.model-info-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.model-info-item {
  display: flex;
  align-items: center;
  padding: 5px 7px;
  position: relative;
  border-radius: 4px;
  transition: all 0.2s ease;
  border-bottom: 1px dotted #eaeaea;
}

.model-info-item:last-child {
  border-bottom: none;
}

/* Add a subtle background on hover */
.model-info-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Color indicators */
.color-indicator {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative;
}

/* Primary agent styling - blue */
.model-info-item:nth-child(1) .color-indicator {
  background-color: #1d4268;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Support agent styling - purple */
.model-info-item:nth-child(2) .color-indicator {
  background-color: #5a6a9c;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Polaris 2.0 styling - teal */
.model-info-item:nth-child(3) .color-indicator {
  background-color: #348792;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.model-info-highlight {
  font-weight: 600;
  color: #444;
}

/* Clear the float after the legend */
.polaris-architecture-wrapper::after {
  content: "";
  display: table;
  clear: both;
}

/* Enhanced title colors to match their sections */
.primary-agent-section .section-title {
  color: #1d4268;
  border-bottom: 2px solid rgba(42, 88, 133, 0.3);
}

.support-agents-section .section-title {
  color: #5a6a9c;
  border-bottom: 2px solid rgba(107, 125, 179, 0.3);
}

.patient-flow-section .section-title {
  color: #348792;
  border-bottom: 2px solid rgba(66, 165, 179, 0.3);
}

/* Hover effects for capability pills */
.capability-pill:hover {
  background-color: rgba(42, 88, 133, 0.2);
  transform: translateY(-1px);
}

.support-capability-pill:hover {
  background-color: rgba(107, 125, 179, 0.2);
  transform: translateY(-1px);
}

@media (max-width: 900px) {
  .caption-legend-container {
    flex-direction: column;
    align-items: center;
  }

  .diagram-caption {
    max-width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }

  .model-info-legend {
    max-width: 90%;
  }
}
