.strategy-section {
  margin-bottom: 4rem;
}

.strategy-title {
  font-size: 1.8rem;
  color: #2a5885;
  margin-bottom: 2rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #eaeaea;
  display: flex;
  align-items: center;
  position: relative;
}

.title-icon {
  font-size: 1.8rem;
  margin-right: 0.75rem;
  background: linear-gradient(135deg, #2a5885, #4a90e2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subsection {
  margin-bottom: 2.5rem;
  background-color: #fff;
  border-radius: 12px;
  padding: 1.75rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  border: 1px solid #f0f0f0;
}

.subsection:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.subsection h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
}

.subsection h3::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2a5885, #4a90e2);
  border-radius: 3px;
}

.subsection h4 {
  font-size: 1.15rem;
  color: #2a5885;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.objective-icon {
  font-size: 1.3rem;
  margin-right: 0.5rem;
}

/* Intro section styling */
.intro-subsection {
  background: linear-gradient(to bottom right, #ffffff, #f8faff);
  border-left: 5px solid #2a5885;
}

.intro-content p {
  font-size: 1rem;
  line-height: 1.7;
  color: #444;
  margin-bottom: 1rem;
}

/* Architecture diagram styling */
.architecture-diagram-container {
  margin: 1.5rem auto 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: visible;
  max-width: 1200px;
}

.diagram-title {
  font-size: 1.2rem;
  color: #2a5885;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
  position: relative;
}

.diagram-title::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background: linear-gradient(90deg, #2a5885, #4a90e2);
  border-radius: 2px;
}

.architecture-diagram-container > div {
  max-width: 100%;
  width: 100%;
}

.diagram-caption {
  font-size: 0.85rem;
  color: #666;
  font-style: italic;
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  padding: 0 20px;
}

.diagram-description {
  margin-top: 1rem;
  max-width: 800px;
  text-align: center;
  padding: 0 20px;
}

.diagram-description p {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #555;
}

.highlight-text {
  background: linear-gradient(90deg, #f0f5ff, #ffffff);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #2a5885;
  border-left: 4px solid #2a5885;
  margin-top: 1rem;
  line-height: 1.6;
}

/* Tabs for objectives */
.objective-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
}

.tab-button {
  background-color: #f0f5ff;
  border: 1px solid #d0e2ff;
  color: #2a5885;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  outline: none;
}

.tab-button:hover {
  background-color: #e0ebff;
  transform: translateY(-2px);
}

.tab-button.active {
  background-color: #2a5885;
  color: white;
  border-color: #2a5885;
}

.tab-icon {
  margin-right: 0.4rem;
  font-size: 1.1rem;
}

.tab-content {
  background-color: #f8faff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 0.5rem;
  position: relative;
  min-height: 300px;
}

.objective-content {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Flow section styling */
.flow-subsection {
  background: linear-gradient(to bottom, #ffffff, #f8faff);
}

.flow-diagram {
  margin: 2rem 0;
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.flow-steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}

.flow-steps::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, #2a5885, #4a90e2);
  z-index: 1;
}

.flow-step {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  padding-left: 5px;
}

.step-number {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #2a5885, #4a90e2);
  color: white;
  font-weight: 600;
  border-radius: 50%;
  margin-right: 1.5rem;
  box-shadow: 0 4px 10px rgba(42, 88, 133, 0.3);
  flex-shrink: 0;
  font-size: 1rem;
}

.step-content {
  flex-grow: 1;
  background-color: #f8faff;
  padding: 1rem 1.25rem;
  border-radius: 8px;
  border-left: 3px solid #2a5885;
  display: flex;
  flex-direction: column;
}

.step-content strong {
  color: #2a5885;
  font-weight: 600;
  font-size: 1.05rem;
  display: block;
  margin-bottom: 0.5rem;
}

.step-content p {
  margin: 0;
  color: #444;
  font-size: 0.95rem;
  line-height: 1.5;
}

.insight-card {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #f0f5ff, #ffffff);
  padding: 1.25rem 1.5rem;
  border-radius: 10px;
  margin-top: 2rem;
  border: 1px solid #d0e2ff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.insight-icon {
  font-size: 2rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.insight-card p {
  margin: 0;
  font-size: 1.05rem;
  color: #333;
  line-height: 1.6;
}

/* Conclusion section styling */
.conclusion-subsection {
  background: linear-gradient(to bottom right, #ffffff, #f0f5ff);
  border-left: 5px solid #2a5885;
}

.conclusion-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.conclusion-card {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.conclusion-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.conclusion-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2a5885, #4a90e2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.conclusion-card h4 {
  font-size: 1.15rem;
  margin-top: 0;
  margin-bottom: 0.75rem;
  color: #2a5885;
}

.conclusion-card p {
  margin: 0;
  font-size: 0.95rem;
  color: #444;
  line-height: 1.6;
}

.final-thought {
  font-size: 1.05rem;
  line-height: 1.7;
  color: #333;
  background-color: #f0f5ff;
  padding: 1.25rem 1.5rem;
  border-radius: 8px;
  font-style: italic;
  border-left: 4px solid #2a5885;
}

/* Content lists */
.content {
  padding: 0.5rem 0;
}

.content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #444;
  margin-bottom: 1rem;
}

.content-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1.5rem;
}

.content-list li {
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
  background-color: #f8f9fa;
  border-left: 3px solid #2a5885;
  border-radius: 0 6px 6px 0;
  font-size: 0.95rem;
  color: #444;
  line-height: 1.5;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  align-items: flex-start;
}

.content-list li:hover {
  transform: translateX(3px);
  background-color: #f0f5ff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.list-icon {
  margin-right: 0.75rem;
  font-size: 1.1rem;
  flex-shrink: 0;
  margin-top: 1px;
}

.flow-list {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.flow-list li {
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: #444;
  line-height: 1.5;
}

.flow-list li strong {
  color: #2a5885;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .conclusion-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .subsection {
    padding: 1.25rem;
  }

  .tab-button {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  .content-list li {
    padding: 0.6rem 0.75rem;
  }

  .flow-step {
    flex-direction: row;
    align-items: center;
    padding-left: 0;
  }

  .step-number {
    width: 32px;
    height: 32px;
    margin-bottom: 0;
    margin-right: 1rem;
    font-size: 0.9rem;
  }

  .flow-steps::before {
    left: 16px;
    height: calc(100% - 32px);
    top: 16px;
  }

  .step-content {
    padding: 0.8rem 1rem;
    width: calc(100% - 50px);
  }
}

/* Animation for hovering cards */
@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 8px 25px rgba(42, 88, 133, 0.2);
  }
  100% {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
}

/* Add this to your existing styles.css */
.content-list li sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -0.25em;
  color: #0066cc;
}

.objective-content sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -0.25em;
  color: #0066cc;
}
