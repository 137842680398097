.safety-section {
  margin-bottom: 4rem;
}

.safety-section h2 {
  font-size: 1.8rem;
  color: #2a5885;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eaeaea;
}

.section-intro {
  font-size: 1.05rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 2rem;
}

.subsection {
  margin-bottom: 2.5rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.subsection h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

/* General content styling */
.content-block {
  padding: 0.5rem 0;
}

.content-block p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

/* Feature lists */
.feature-list {
  list-style-type: none;
  padding-left: 0;
}

.feature-list li {
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: #f8f9fa;
  border-left: 3px solid #2a5885;
  border-radius: 0 4px 4px 0;
  font-size: 0.95rem;
  color: #444;
}

.feature-list li:hover {
  background-color: #eef2f7;
}

/* Legacy compatibility for skeleton items */
.skeleton-content {
  padding: 0.5rem 0;
}

.skeleton-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.skeleton-list {
  list-style-type: none;
  padding-left: 0;
}

.skeleton-list li {
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: #f8f9fa;
  border-left: 3px solid #2a5885;
  border-radius: 0 4px 4px 0;
  font-size: 0.95rem;
  color: #444;
}

.skeleton-list li:hover {
  background-color: #eef2f7;
}

/* Metrics grid */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.metric-card {
  background-color: #f5f7fa;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.metric-card h4 {
  font-size: 1.05rem;
  color: #2a5885;
  margin-bottom: 0.5rem;
}

.metric-card p {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
}

.validation-method {
  font-style: italic;
  color: #666;
  font-size: 0.95rem;
  padding: 0.5rem;
  background-color: #f0f4f8;
  border-radius: 4px;
}

/* Polaris Architecture Visualization */
.architecture-visual {
  margin: 2rem 0;
  background-color: #f8fafe;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.visual-title {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: #2a5885;
  margin-bottom: 1.5rem;
}

.constellation-diagram {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.primary-model {
  background-color: #3a7ca5;
  color: white;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
}

.specialist-models {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.specialist {
  background-color: #5aa9d6;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  text-align: center;
  flex: 1;
  min-width: 150px;
  max-width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.benefits {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.benefit {
  background-color: #e6f2f8;
  color: #2a5885;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.85rem;
  text-align: center;
  font-weight: 500;
}

/* Three-phase process visualization */
.process-steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
}

.process-step {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  background-color: #f8fafe;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #2a5885;
  color: white;
  border-radius: 50%;
  font-weight: 600;
  flex-shrink: 0;
}

.step-content {
  flex-grow: 1;
}

.step-content h4 {
  font-size: 1.1rem;
  color: #2a5885;
  margin-bottom: 0.5rem;
}

.step-content p {
  font-size: 0.95rem;
  color: #555;
  margin: 0;
}

.test-results {
  margin-top: 1rem;
  font-weight: 500;
  color: #333;
  background-color: #e6f2f8;
  padding: 0.75rem;
  border-radius: 6px;
  text-align: center;
}

/* Case study box */
.case-study-box {
  background-color: #f0f7ff;
  border-left: 4px solid #2a5885;
  border-radius: 0 6px 6px 0;
  padding: 1rem;
  margin: 1.5rem 0;
}

.case-study-box h4 {
  color: #2a5885;
  font-size: 1.05rem;
  margin-bottom: 0.5rem;
}

.case-study-box p {
  font-size: 0.95rem;
  color: #444;
  margin: 0;
}

/* Advisory councils grid */
.council-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;
}

.council-card {
  background-color: #f5f7fa;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.council-card:hover {
  transform: translateY(-2px);
}

.council-card h4 {
  font-size: 1rem;
  color: #2a5885;
  margin-bottom: 0.5rem;
}

.council-card p {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

/* Tasks grid */
.tasks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;
}

.task-card {
  background-color: #f5f7fa;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
}

.task-card:hover {
  background-color: #eef2f7;
}

.task-card h4 {
  font-size: 1rem;
  color: #2a5885;
  margin-bottom: 0.5rem;
}

.task-card p {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .metrics-grid,
  .council-grid,
  .tasks-grid {
    grid-template-columns: 1fr;
  }

  .specialist-models {
    flex-direction: column;
    align-items: center;
  }

  .specialist {
    max-width: 100%;
  }
}
