.expertise-section {
  margin-bottom: 3rem;
}

.expertise-section h2 {
  font-size: 1.8rem;
  color: #2a5885;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eaeaea;
}

.section-intro {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
  line-height: 1.7;
}

.subsection {
  margin-bottom: 2.5rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.subsection h3 {
  font-size: 1.5rem;
  color: #2a5885;
  margin-bottom: 1.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eaeaea;
}

.expertise-content {
  padding: 1rem 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  border-left: 4px solid #2a5885;
}

.expertise-content p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.feature-list {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.feature-list li {
  margin-bottom: 0.8rem;
  line-height: 1.5;
}

.prompt-component {
  margin-bottom: 2rem;
}

.prompt-component h4 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.8rem;
}

.prompt-box {
  background-color: #f0f4f8;
  border: 1px solid #d0e0f0;
  border-radius: 6px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.prompt-box .prompt-text {
  margin-bottom: 1rem;
  font-weight: 500;
}

.prompt-box ul {
  padding-left: 1.2rem;
}

.prompt-box li {
  margin-bottom: 0.5rem;
}

.skeleton-content {
  padding: 0.5rem 0;
}

.skeleton-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.skeleton-list {
  list-style-type: none;
  padding-left: 0;
}

.skeleton-list li {
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: #f8f9fa;
  border-left: 3px solid #2a5885;
  border-radius: 0 4px 4px 0;
  font-size: 0.95rem;
  color: #444;
}

.skeleton-list li:hover {
  background-color: #eef2f7;
}

@media (max-width: 768px) {
  .expertise-content {
    padding: 1rem;
  }

  .prompt-box {
    padding: 1rem;
  }
}
