.conversation-simulator {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 30px 0;
}

.simulator-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.scenario-toggle-button {
  background-color: #2a5885;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.scenario-toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.scenario-toggle-button:hover::after {
  animation: ripple 1s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(25, 25);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.scenario-toggle-button:hover {
  background-color: #1d4268;
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.simulator-title {
  margin: 0;
  flex: 1;
  text-align: center;
  padding-right: 20px;
}

.simulator-description {
  text-align: center;
  font-size: 1rem;
  color: #555;
  margin-bottom: 25px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
}

.simulator-container {
  display: grid;
  grid-template-columns: 1fr 0.8fr 1.2fr;
  gap: 20px;
  margin-bottom: 20px;
}

/* Chat Section */
.chat-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-top: 4px solid #2a5885;
}

.section-title {
  font-size: 1.1rem;
  color: #2a5885;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e7f2;
  text-align: center;
}

.chat-messages-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 450px;
  overflow-y: auto;
  padding: 10px 5px;
}

.message-container {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.patient-message {
  align-self: flex-start;
}

.agent-message {
  align-self: flex-end;
}

.message-sender {
  font-size: 0.8rem;
  color: #777;
  margin-bottom: 5px;
}

.message-bubble {
  padding: 10px 15px;
  border-radius: 12px;
  font-size: 0.95rem;
  line-height: 1.4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.patient-bubble {
  background-color: #e9ecef;
  color: #333;
  border-bottom-left-radius: 2px;
}

.agent-bubble {
  background-color: #2a5885;
  color: white;
  border-bottom-right-radius: 2px;
}

/* Agent Tracker Section */
.agent-tracker-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-top: 4px solid #34a853;
}

.agents-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 450px;
  overflow-y: auto;
  padding-right: 5px;
}

.agent-box {
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 6px;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.agent-box.active {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  animation: highlight-pulse 2s infinite;
}

@keyframes highlight-pulse {
  0% {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }
  50% {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
  }
  100% {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }
}

.agent-name {
  font-weight: 600;
  font-size: 0.95rem;
  margin-bottom: 5px;
  color: #333;
}

.agent-status {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.agent-box.active .agent-status::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #34a853;
  margin-right: 5px;
  animation: status-pulse 2s infinite;
}

@keyframes status-pulse {
  0% {
    opacity: 0.6;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.6;
    transform: scale(0.8);
  }
}

/* Agent Type Label */
.agent-type {
  position: absolute;
  bottom: 8px;
  right: 10px;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.65rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.agent-type.primary {
  background-color: rgba(42, 88, 133, 0.15);
  color: #2a5885;
  border: 1px solid rgba(42, 88, 133, 0.3);
}

.agent-type.support {
  background-color: rgba(52, 168, 83, 0.15);
  color: #34a853;
  border: 1px solid rgba(52, 168, 83, 0.3);
}

/* Prompt Section */
.prompt-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-top: 4px solid #5a6a9c;
}

.prompt-container {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.prompt-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #666;
  margin-bottom: 10px;
}

.prompt-content {
  background-color: #f0f4f8;
  padding: 15px;
  border-radius: 6px;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #333;
  margin-bottom: 15px;
  white-space: pre-wrap;
  min-height: 200px;
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

.prompt-metadata {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.metadata-item {
  font-size: 0.85rem;
}

.metadata-label {
  color: #666;
  margin-right: 5px;
}

.metadata-value {
  font-weight: 600;
}

/* Button Section */
.button-section {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 25px;
}

.next-step-button,
.restart-button {
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.next-step-button {
  background-color: #34a853;
  color: white;
  box-shadow: 0 3px 8px rgba(52, 168, 83, 0.3);
}

.next-step-button:hover {
  background-color: #2d9249;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(52, 168, 83, 0.4);
}

.next-step-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.restart-button {
  background-color: #5a6a9c;
  color: white;
  box-shadow: 0 3px 8px rgba(90, 106, 156, 0.3);
}

.restart-button:hover {
  background-color: #4d5b89;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(90, 106, 156, 0.4);
}

/* Add scrollbar styling for agent container */
.agents-container::-webkit-scrollbar {
  width: 6px;
}

.agents-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.agents-container::-webkit-scrollbar-thumb {
  background: #c1d1e0;
  border-radius: 3px;
}

.agents-container::-webkit-scrollbar-thumb:hover {
  background: #a5b9ce;
}

/* Update agent colors based on specialist type */
.agent-box[style*="borderColor: green"] .agent-name {
  color: #34a853;
}

.agent-box[style*="borderColor: purple"] .agent-name {
  color: #673ab7;
}

.agent-box[style*="borderColor: orange"] .agent-name {
  color: #ff7043;
}

.agent-box[style*="borderColor: teal"] .agent-name {
  color: #009688;
}

.agent-box[style*="borderColor: maroon"] .agent-name {
  color: #880e4f;
}

.agent-box[style*="borderColor: brown"] .agent-name {
  color: #795548;
}

.agent-box[style*="borderColor: blue"] .agent-name {
  color: #2a5885;
}

.agent-box[style*="borderColor: darkcyan"] .agent-name {
  color: #008b8b;
}

.agent-box[style*="borderColor: darkgoldenrod"] .agent-name {
  color: #b8860b;
}

/* Allow more space for the additional agents */
.agents-container {
  max-height: 450px;
}

/* Explanatory tooltip about support agents */
.agent-tracker-section .section-title {
  position: relative;
}

.agent-tracker-section .section-title::after {
  content: "Hover for info";
  position: absolute;
  right: 5px;
  top: 0;
  font-size: 10px;
  color: #666;
  font-weight: normal;
  cursor: help;
}

/* Agent Type Tooltip */
.agent-type-tooltip {
  display: none;
  position: absolute;
  top: 50%;
  right: -265px;
  transform: translateY(-50%);
  width: 250px;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 12px;
  font-size: 11px;
  color: #333;
  z-index: 100;
  text-align: left;
  line-height: 1.4;
  border: 1px solid #ddd;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.agent-type-tooltip:before {
  content: "";
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background-color: white;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.agent-box:hover .agent-type-tooltip {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.agent-type-tooltip strong {
  display: block;
  margin-bottom: 8px;
  color: #2a5885;
  font-size: 12px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.agent-type-tooltip p {
  margin: 0;
  font-size: 11px;
  color: #555;
}

.agent-type-tooltip ul {
  margin: 5px 0 0;
  padding-left: 15px;
  list-style-type: none;
}

.agent-type-tooltip li {
  position: relative;
  padding: 3px 0;
  font-size: 11px;
  color: #444;
  padding-left: 15px;
}

.agent-type-tooltip li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #2a5885;
  font-size: 14px;
  line-height: 1;
}

/* Media query for small screens - display tooltip below on mobile */
@media (max-width: 1100px) {
  .agent-type-tooltip {
    top: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
  }

  .agent-type-tooltip:before {
    left: 50%;
    top: -6px;
    transform: translateX(-50%) rotate(45deg);
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: none;
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .simulator-container {
    grid-template-columns: 1fr 1fr;
  }

  .prompt-section {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  .simulator-container {
    grid-template-columns: 1fr;
  }

  .prompt-section {
    grid-column: span 1;
  }

  .chat-messages-container {
    max-height: 300px;
  }

  .prompt-content {
    min-height: 150px;
  }
}

/* Add scrollbar styling for prompt content */
.prompt-content::-webkit-scrollbar {
  width: 6px;
}

.prompt-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.prompt-content::-webkit-scrollbar-thumb {
  background: #c1d1e0;
  border-radius: 3px;
}

.prompt-content::-webkit-scrollbar-thumb:hover {
  background: #a5b9ce;
}

/* Enhance agent status indicator */
.agent-status {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.agent-box.active .agent-status::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #34a853;
  margin-right: 5px;
  animation: status-pulse 2s infinite;
}

@keyframes status-pulse {
  0% {
    opacity: 0.6;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.6;
    transform: scale(0.8);
  }
}
