.workflow-subsection {
  margin: 3rem 0;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
}

.workflow-intro {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.workflow-steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.workflow-step {
  display: flex;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.workflow-step:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  background-color: #0066cc;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.2rem;
}

.step-content {
  flex: 1;
}

.step-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 0.5rem 0;
}

.step-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #4a4a4a;
  margin: 0;
}

.step-description sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -0.25em;
}

.step-description sub {
  color: #0066cc;
}

.workflow-conclusion {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #e8f4ff;
  border-radius: 8px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .workflow-subsection {
    padding: 1rem;
  }

  .workflow-step {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .step-number {
    min-width: 32px;
    height: 32px;
    font-size: 1rem;
  }

  .step-title {
    font-size: 1.1rem;
  }

  .step-description {
    font-size: 0.95rem;
  }
}
